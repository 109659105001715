<ion-app dir="{{textDir}}">
  <!-- when=false means that the split pane will never expand automatically -->
  <!-- For more info check https://ionicframework.com/docs/api/split-pane -->
  <ion-split-pane contentId="menu-content">
    <ion-menu contentId="menu-content" class="app-sidemenu">
      <ion-header>
        <ion-toolbar>
          <ion-row class="user-details-wrapper">
            <ion-col size="4">
              <app-aspect-ratio [ratio]="{w: 1, h: 1}">
                <app-image-shell class="user-avatar" animation="spinner" [src]="'./assets/app-icon.svg'"></app-image-shell>
              </app-aspect-ratio>
            </ion-col>
            <ion-col class="user-info-wrapper" size="8">
              <ion-row></ion-row>
              <h3 class="user-name">Demo</h3>
              <h3 class="user-name">User</h3>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-list-header>
            <ion-label>{{ 'CHARGING' | translate }}</ion-label>
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
          <ion-item [routerLink]="['/maps']">
            <ion-icon slot="start" name="map-outline"></ion-icon>
              <ion-label>
                {{ 'MAP' | translate }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>                         
        </ion-list>
        <ion-list>
          <ion-list-header>
            <ion-label>{{'ACCOUNT' | translate}}</ion-label>
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item [routerLink]="['/profile']">
              <ion-icon slot="start" name="person-outline"></ion-icon>
              <ion-label>
                {{ 'PROFILE' | translate}}
              </ion-label>
            </ion-item>   
            <!--  
            <ion-item [routerLink]="['/list']">
              <ion-icon slot="start" name="log-in-outline"></ion-icon>
              <ion-label>
                {{'LOGIN' | translate}}
              </ion-label>
            </ion-item> 
            <ion-item [routerLink]="['/list']">
              <ion-icon slot="start" name="person-add-outline"></ion-icon>
              <ion-label>
                {{'REGISTER' | translate}}
              </ion-label>
            </ion-item>  
          -->
            <ion-item [routerLink]="['/login']">
              <ion-icon slot="start" name="log-out-outline"></ion-icon>
              <ion-label>
                {{'LOGOUT' | translate}}
              </ion-label>
            </ion-item>                         
          </ion-menu-toggle>
        </ion-list>
        <ion-list>
          <ion-list-header>
            <ion-label>{{'INFORMATION' | translate}}</ion-label>
          </ion-list-header>
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerLink]="['/faq']">
              <ion-icon slot="start" name="help-buoy-outline"></ion-icon>
              <ion-label>
                {{'FAQ' | translate}}
              </ion-label>
            </ion-item> 
            <ion-item [routerLink]="['/contact']">
              <ion-icon slot="start" name="call-outline"></ion-icon>
              <ion-label>
                {{'CONTACTUS'| translate}}
              </ion-label>
            </ion-item>              
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <!-- Main app content get's rendered in this router-outlet -->
    <!-- Main app content get's rendered in this router-outlet -->
    <ion-content id="menu-content">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button class="more-btn" size="small" fill="clear" (click)="openLanguageChooser()">
              <ion-icon slot="start" ios="ellipsis-horizontal" md="ellipsis-vertical"></ion-icon>{{'CURRENTLANGUAGE' | translate }}
            </ion-button>
            <ion-title>
              <img class="top-menu-logo" src="../assets/asplogo.svg" alt="Aqua Superpower Logo">   
          </ion-title>   
          </ion-buttons>    
        </ion-toolbar>
      </ion-header>  
      <ion-content>
        <ion-router-outlet></ion-router-outlet>    
      </ion-content>
    </ion-content>
  </ion-split-pane>
</ion-app>
