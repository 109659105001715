import { Component } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { AlertController } from '@ionic/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { HistoryHelperService } from './utils/history-helper.service';
import { LanguageService } from './language/language.service';
import { ownKeys } from 'core-js/es6/reflect';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss',
    './app.component.scss'
  ]
})
export class AppComponent {
  textDir = 'ltr';
  translations;
  available_languages = []; 
  public currentLanguage : string = ""; 

  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    public translate: TranslateService,
    public historyHelper: HistoryHelperService,
    public alertController: AlertController,
    public languageService: LanguageService       
  ) {
    this.initializeApp();
    this.setLanguage();
  }

  async initializeApp() {
    try {
     await SplashScreen.hide();
    } catch (err) {
     console.log('This is normal in a browser', err);
    }
  }

  getLanguage(): string {
    return this.currentLanguage;

  }

  setLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');

    // this is to determine the text direction depending on the selected language
    // for the purpose of this example we determine that only arabic and hebrew are RTL.
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
    // });
    this.getTranslations();
    this.translate.onLangChange.subscribe(() => this.getTranslations());    
    this.translate.get(this.translate.currentLang.toUpperCase()).subscribe((word => this.currentLanguage = word));
  
  }

  getTranslations(){
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => this.translations = translations);  
  }

  async openLanguageChooser() {
    this.available_languages = this.languageService.getLanguages()
    .map(item =>
      ({
        name: item.name,
        type: 'radio',
        label: item.name,
        value: item.code,
        checked: item.code === this.translate.currentLang
      })
    );

    let selectLanguage: string = '';
    this.translate.get("SELECT_LANGUAGE").subscribe((res:string) => selectLanguage=res);

    let cancel: string = '';
    this.translate.get("CANCEL").subscribe((res:string) => cancel=res);    

    let ok: string = '';
    this.translate.get("OK").subscribe((res:string) => ok=res);     

    const alert = await this.alertController.create({
      header: selectLanguage,
      inputs: this.available_languages,
      cssClass: 'language-alert',
      buttons: [
        {
          text: cancel,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        }, {
          text: ok,
          handler: (data) => {
            if (data) {
              this.translate.use(data);
            }
          }
        }
      ]
    });
    await alert.present();

  }  

  
}
