import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'profile',
    loadChildren: () => import('./user/profile/user-profile.module').then(m => m.UserProfilePageModule)
  },
  {
    path: 'page-not-found',
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
  {
    path: 'maps',
    loadChildren: () => import('./maps/maps.module').then(m => m.MapsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },  
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
  },  
  {
    path: 'contact',
    loadChildren: () => import('./contactus/contactus.module').then(m => m.ContactusModule)
  },
  /*
  {
    path: 'privacy',
    loadChildren: () => import('./privacy-policy/privacy-policy.page').then(m => m.PrivacyPolicyPage)
  },   
  */ 
  {
    path: '**',
    redirectTo: 'page-not-found'
  }
];
@NgModule({
  imports: [
    TranslateModule,
    RouterModule.forRoot(routes, {
      // This value is required for server-side rendering to work.
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
